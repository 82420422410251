var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Add User")])]),_c('CCardBody',[_c('CForm',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.validator();_vm.submit()}}},[_c('CRow',[_c('CCol',{attrs:{"sm":"3"}},[_c('CInput',{attrs:{"description":_vm.form.username.inv,"label":"E-mail"},model:{value:(_vm.form.username.val),callback:function ($$v) {_vm.$set(_vm.form.username, "val", $$v)},expression:"form.username.val"}})],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CInput',{attrs:{"description":_vm.form.password.inv,"type":"password","label":"Password"},model:{value:(_vm.form.password.val),callback:function ($$v) {_vm.$set(_vm.form.password, "val", $$v)},expression:"form.password.val"}})],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CInput',{attrs:{"description":_vm.form.first_name.inv,"label":"First Name"},model:{value:(_vm.form.first_name.val),callback:function ($$v) {_vm.$set(_vm.form.first_name, "val", $$v)},expression:"form.first_name.val"}})],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CInput',{attrs:{"description":_vm.form.last_name.inv,"label":"Last Name"},model:{value:(_vm.form.last_name.val),callback:function ($$v) {_vm.$set(_vm.form.last_name, "val", $$v)},expression:"form.last_name.val"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"3"}},[_c('CSelect',{attrs:{"description":_vm.form.sex.inv,"label":"Sex","options":[
                                        {value: 1, label:'Male'},
                                        {value: 2, label:'Female'},
                                        {value: 3, label:'Other'} ],"placeholder":"Please Select"},on:{"change":function($event){_vm.form.sex.val=$event.target.value}},model:{value:(_vm.form.sex.val),callback:function ($$v) {_vm.$set(_vm.form.sex, "val", $$v)},expression:"form.sex.val"}})],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CSelect',{attrs:{"description":_vm.form.is_active.inv,"label":"State","options":[
                                        {value: 'true', label:'Active'},
                                        {value: 'false', label:'Inactive'} ],"placeholder":"Please Select"},on:{"change":function($event){_vm.form.is_active.val=$event.target.value}},model:{value:(_vm.form.is_active.val),callback:function ($$v) {_vm.$set(_vm.form.is_active, "val", $$v)},expression:"form.is_active.val"}})],1),_c('CCol',{attrs:{"sm":"3"}},[_c('CSelect',{attrs:{"description":_vm.form.is_guest.inv,"label":"Guest User","options":[
                                        {value: 'true', label:'Yes'},
                                        {value: 'false', label:'No'} ],"placeholder":"Please Select"},on:{"change":function($event){_vm.form.is_guest.val=$event.target.value}},model:{value:(_vm.form.is_guest.val),callback:function ($$v) {_vm.$set(_vm.form.is_guest, "val", $$v)},expression:"form.is_guest.val"}})],1)],1),_c('br')],1),_c('br'),_c('div',{staticClass:"form-group form-actions"},[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){_vm.validator();_vm.submit()}}},[_vm._v(" Submit")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }